import React from 'react'

function Logo() {
  return (
    <>
    <div className="text-center mt-2"><img className="logo" src="https://raw.githubusercontent.com/ermin1990/glutenfree/master/public/logo.png" alt="" /></div>
    
    </>
  )
}

export default Logo