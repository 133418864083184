import React from 'react'

function Loader() {
  return (
    <>
    <div className='container p-1'><img className='loader' src="https://raw.githubusercontent.com/ermin1990/glutenfree/master/public/cookieloader1.gif"  alt='loader'/></div>
    <p>Učitavamo podatke, molimo vas sačekajte</p>
    </>

  )
}

export default Loader